import { fetchApi } from "../../utils/api";

export async function submitForm(payload) {
    try {
        
        let response = await fetchApi(`/app/submit/form`, 'post', {...payload, type: 'apply'});
        if (response.code !== 200) {
            return response;
        }
        return response;
    } catch (error) {
        console.log(error);
        return {
            rows: [],
        };
    }
}