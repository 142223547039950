import {
    LOAD_LIST_JOB, LOAD_JD
} from '@/constants/ActionTypes';
import { fetchApi } from "../../utils/api";

export function actionSaveJD(payload) {
    return {
        type: LOAD_JD,
        payload
    };
}
export const getJD = async (payload, dispatch) => {
    const { url } = payload;
    let res = await fetchApi(url, 'get', {
        headers: {
            'Content-Type': 'application/pdf',
        }
    });
    if (res.code === 200) {
        console.log(res);
    }

};
export function actionSaveListJob(payload) {
    return {
        type: LOAD_LIST_JOB,
        payload
    };
}
export const getListJob = async (payload, dispatch) => {
    try {
        // const count = payload.count || 0;
        const limit = 20;
        // const total = payload.count || 0;
        // const page = Math.ceil(count / limit) + 1;
        const dataSend = payload;
        // dataSend.page = payload.page || page;
        dataSend.limit = limit;
        // console.log(dataSend);
        let response = await fetchApi(`/app/job/get-list-job`, "get", dataSend);
        if (response.code === 200) {
            let data = response.data.rows;
            // if (page !== 1) {
            //     data = [...payload.old_data, ...response.data.rows];
            // }
            dispatch(actionSaveListJob({
                rows: data,
                total: response.data.total,
            }));
        }
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};
