import moment from 'moment';
export const urlValidate = (url) => {
    let re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

    return re.test(url);
};
export const formatMoney = (num) => {
    if (!num || num === "") return "0";
    let numberMoney = Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    numberMoney = (numberMoney + '').replace(/,/g, '*');
    numberMoney = numberMoney.replace(/\./g, ',');
    numberMoney = numberMoney.replace(/\*/g, '.');
    return numberMoney;
};
export const checkTypeFileDoc = (file)=>{
    if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/msword' || file.type === 'application/pdf' ){
        return true;
    }
    
        return false;
    
};
export const trimTextUnder = (str) => {
    let countText = str.length;
    const lastSpace = str.lastIndexOf(' ');
    if (countText > 0 && lastSpace === -1) {
        return str = str.slice(0);
    }
    return str.slice(lastSpace);
};

export const formatSalary = (item) => {
    let sal = `${(item?.salary_start === null || item?.salary_start === 0) && item.salary_unit === 2 ? "$" : ''}             
               ${item?.salary_start === null || item?.salary_start === 0 ? 'Upto ' : ''}
               ${item?.salary_start === null || item?.salary_start === 0 ? '' : formatMoney(Number(item.salary_start))}`;
    if ((item?.salary_start !== null || item?.salary_start !== 0) && item.salary_end !== null) {
        let sal_end = `${item.salary_unit === 2 ? "$" : ''}${formatMoney(Number(item?.salary_end))}${item.salary_unit === 1 ? " VNĐ" : ''} ${item.salary_unit === 3 ? " Triệu " : ''}`;
        sal = `${sal} 
                ${item.salary_start === 0 ? ' ' : item.salary_unit === 1 ? ' VNĐ -' : item.salary_unit === 3 && item?.salary_start !== null ? ' Triệu -' : ''} 
                ${sal_end}`;
    }
    else {
        sal = 'Thoả Thuận';
    }
    return sal;
};

// console.log('listJob', listJob);
export const formatDate = (item) => {
    let date;
    let startDate = `${item?.start_date === null ? '' : moment(item.start_date).format('DD/MM')}`;
    let endDate = `${item?.end_date === null ? '' : moment(item.end_date).format('DD/MM')}`;
    return date = `${startDate} ${item?.start_date === null || item?.end_date === null ? '' : ' - '} ${endDate}`;
};
