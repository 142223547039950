import { message } from "antd";
import {fetchApiUpload} from "../../utils/api";

export const uploadOneFile = async (payload) => {
    try {
        const response = await fetchApiUpload(
            `/api/uploadFileContact`,
            "post",
            payload,
        );
        if (response.code === 400) {
            message.error(response?.message);
            setTimeout(() => {
                message.destroy();
            }, 2000);
            return;
        }
        // console.log(response?.data?.filePaths?.file);
        return response?.data?.filePaths?.file;
    } catch (error) {
        alert(error || error?.message);
        console.log("uploadOneFileError", error);
    }
};